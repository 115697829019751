import http from "@/utils/http";

export async function get_payments_list(data){
    const req = (await http.get(`/payments/admin/filter`,{params:data}));
    return req.data;
}

export async function release_payment(data){
    const req = (await http.post(`/payments/transfer-funds`,data));
    return req.data;
}

export async function refund_payment(data){
    const req = (await http.post(`/payments/refund-payment-intent`,data));
    return req.data;
}